<script lang="ts">
const SELECTION_COLUMN_KEY = '__selection'
</script>
<script lang="ts" setup generic="T extends Record<string, unknown>">
import { h, computed, useSlots, ref, watch, nextTick } from 'vue';
import type { VNode, UnwrapNestedRefs } from 'vue';
import { useVModel, unrefElement } from '@vueuse/core';
import { get, toString, isEqual } from 'lodash-es';
import { ObCheckbox } from '../checkbox';
import { ObLinearProgress } from '../linear-progress';
import { ObScrollableContainer } from '../scrollable-container';
import { pxOrValue, isVNodeEmpty } from '../../utils';
import type {
  DataTableColumn,
  DataTableSorting,
  DataTablePagination,
  DataTableState,
  DataTableRowClickEventPayload,
  DataTableCellClickEventPayload,
  DataTableRowHoverEventPayload,
} from './types';
import ObDataTableRow from './ob-data-table-row.vue';
import ObDataTableBodyCell from './ob-data-table-body-cell.vue';
import ObDataTableHeadCell from './ob-data-table-head-cell.vue';
import ObDataTablePagination from './ob-data-table-pagination.vue';
import style from './ob-data-table.module.scss';

type Row = T;
type Column = DataTableColumn<Row>;

interface Props {
  columns: Column[];
  rows: Row[];
  rowKey?: string;
  totalRows?: number; // for external pagination
  compact?: boolean;
  stickyHead?: boolean;
  rowsSelection?: boolean;
  pagination?: DataTablePagination;
  paginationMode?: 'internal' | 'external';
  pageSizeOptions?: number[];
  sorting?: DataTableSorting;
  sortingMode?: 'internal' | 'external';
  selectedRows?: Row[];
  loading?: boolean;
  paginationEnabled?: boolean;
  visibleColumns?: string[];
}

const props = withDefaults(defineProps<Props>(), {
  compact: false,
  totalRows: undefined,
  rowKey: undefined,
  stickyHead: true,
  rowsSelection: false,
  pagination: (): DataTablePagination => ({ page: 1, pageSize: 25 }),
  paginationMode: 'internal',
  paginationText: undefined,
  pageSizeOptions: () => [25, 50, 100],
  sorting: (): DataTableSorting => ({ sortBy: null, sortOrder: 'desc' }),
  sortingMode: 'internal',
  selectedRows: () => [],
  loading: false,
  paginationEnabled: true,
  visibleColumns: undefined,
});

const emit = defineEmits<{
  'update:sorting': [value: DataTableSorting];
  'update:pagination': [value: DataTablePagination];
  'update:selectedRows': [selected: Row[]];
  stateChange: [state: DataTableState]; // TODO: better event name?
  rowClick: [payload: DataTableRowClickEventPayload<any>];
  rowHover: [payload: DataTableRowHoverEventPayload<any>];
  cellClick: [payload: DataTableCellClickEventPayload<any>];
}>();

interface Slots {
  // cell and head
  [k: string]: (props: {
    row: Row;
    column: Column;
    rawValue: any;
    value: string;
    rowIndex: number;
  }) => any;
}

defineSlots<Slots>();

// TODO: patch flags https://vuejs.org/guide/extras/rendering-mechanism.html
// TODO: simplify ObDataTableHeadCell
// TODO: typed rawValue



const selectedRows = useVModel(props, 'selectedRows', emit, {
  passive: true,
  deep: true,
});

const sorting = useVModel(props, 'sorting', emit, {
  passive: true,
  deep: true,
});

const pagination = useVModel(props, 'pagination', emit, {
  passive: true,
  deep: true,
});

const state = computed<DataTableState>(() => ({
  ...sorting.value,
  ...pagination.value,
}));

function emitStateChange() {
  // `nextTick` is needed to make sure `stateChange` emits after update:(pagination|sorting)
  nextTick(() => {
    emit('stateChange', state.value);
  });
}

function setSort(column: Column) {
  if (!column.sortable) {
    return;
  }

  if (sorting.value.sortBy !== column.key) {
    sorting.value = {
      sortBy: column.key,
      sortOrder: 'desc',
    };
  } else if (sorting.value.sortOrder === 'asc') {
    sorting.value = {
      sortBy: null,
      sortOrder: 'asc',
    };
  } else {
    sorting.value = {
      sortBy: sorting.value.sortBy,
      sortOrder: 'asc',
    };
  }

  // TODO: keep selection for internal sorting/pagination if page is only 1?
  selectedRows.value = [];

  pagination.value = {
    page: 1,
    pageSize: pagination.value.pageSize,
  };

  emitStateChange();
}

const scrollerEl = ref<HTMLElement>();

watch(state, (newVal, oldVal) => {
  if (isEqual(newVal, oldVal)) {
    return;
  }

  const el = unrefElement(scrollerEl);

  if (el) {
    el.scrollTop = 0;
  }
});

const visibleColumns = computed(() => {
  if (!props.visibleColumns) {
    return props.columns;
  }

  return props.columns.filter(({ key, alwaysVisible }) => {
    return alwaysVisible || props.visibleColumns?.includes(key);
  });
});

const pinnedStartColumns = computed(() => {
  return visibleColumns.value.reduce<Column[]>((acc, item) => {
    if (item.pinned === 'start') {
      acc.push(item);
    }
    return acc;
  }, []);
});

const pinnedEndColumns = computed(() => {
  return visibleColumns.value.reduce<Column[]>((acc, item) => {
    if (item.pinned === 'end') {
      acc.push(item);
    }

    return acc;
  }, []);
});

const notPinnedColumns = computed(() => {
  return visibleColumns.value.filter(({ pinned }) => !pinned);
});

function getValue(row: Row, column: Column, rowIndex: number): unknown {
  if (typeof column.valueGetter === 'function') {
    return column.valueGetter(row, rowIndex);
  }

  if (column.value === null) {
    return null;
  }

  return get(row, column.value);
}

const rows = computed<Row[]>(() => {
  if (props.sortingMode !== 'internal' && props.paginationMode !== 'internal') {
    return props.rows;
  }

  const { sortBy, sortOrder } = sorting.value;

  const sortColumn = visibleColumns.value.find(({ key }) => key === sortBy);

  const sortedRows =
    props.sortingMode === 'internal' && sortColumn
      ? [...props.rows].sort((a, b) => {
          if (typeof sortColumn.sort === 'function') {
            return sortColumn.sort(a, b, sortOrder);
          }

          const aValue = getValue(a, sortColumn, props.rows.indexOf(a));
          const bValue = getValue(b, sortColumn, props.rows.indexOf(b));

          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sortOrder === 'desc'
              ? bValue.localeCompare(aValue)
              : aValue.localeCompare(bValue);
          }

          if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sortOrder === 'desc' ? bValue - aValue : aValue - bValue;
          }

          // TODO: try to compare string vs number / number vs string;
          return 0;
        })
      : props.rows;

  const { page, pageSize } = pagination.value;

  const visibleRows =
    props.paginationMode === 'internal'
      ? sortedRows.slice((page - 1) * pageSize, page * pageSize)
      : sortedRows;

  return visibleRows;
});

function getRowKey(row: Row): string | undefined {
  return props.rowKey ? toString(get(row, props.rowKey)) : undefined;
}

function extractRowsKeys(rows: Row[]): string[] {
  return rows.map((row, i) => getRowKey(row) ?? i.toString());
}

const rowsKeys = computed(() => extractRowsKeys(rows.value));
const selectedRowsKeys = computed(() => extractRowsKeys(selectedRows.value as Row[]));

const allRowsSelected = computed<boolean>(() => {
  if (!selectedRowsKeys.value.length) {
    return false;
  }
  if (rowsKeys.value.length !== selectedRowsKeys.value.length) {
    return false;
  }
  return !rowsKeys.value.some((key) => !selectedRowsKeys.value.includes(key));
});

function isRowSelected(row: Row): boolean {
  const key = getRowKey(row);
  return key ? selectedRowsKeys.value.includes(key) : false;
}

const totalRows = computed(() => props.totalRows ?? props.rows.length);

/* Render */

const slots = useSlots();

function getGridTemplateForColumns(columns: Column[]) {
  return columns.map(({ width, maxWidth, minWidth, flex }) => {
    if (width) {
      return pxOrValue(width);
    }

    if (flex) {
      return minWidth ? `minmax(minWidth, ${flex}fr)` : `${flex}fr`;
    }

    if (minWidth && maxWidth) {
      return `minmax(${pxOrValue(minWidth)}, ${pxOrValue(maxWidth)})`;
    }

    if (minWidth) {
      return `minmax(${pxOrValue(minWidth)}, max-content)`;
    }

    if (maxWidth) {
      return `minmax(min-content, ${pxOrValue(maxWidth)})`;
    }

    return 'max-content';
  });
}

const pinnedStartGridTemplate = computed(() => {
  const result = [];

  result.push('min-content'); // selection mark, keep it only if no rowsSelection

  if (props.rowsSelection) {
    result.push('max-content'); // checkbox
  }

  result.push(...getGridTemplateForColumns(pinnedStartColumns.value));

  return result;
});

const pinnedEndGridTemplate = computed(() => {
  return getGridTemplateForColumns(pinnedEndColumns.value);
});

const mainGridTemplate = computed(() => [
  ...getGridTemplateForColumns(notPinnedColumns.value),
  'auto', // dumb column
]);

const gridTemplate = computed(() => {
  return [
    ...pinnedStartGridTemplate.value,
    ...mainGridTemplate.value,
    ...pinnedEndGridTemplate.value,
  ];
});

const pinnedStartGridColumn = computed(() => {
  if (!pinnedStartGridTemplate.value.length) {
    return 'auto';
  }

  return `1 / ${pinnedStartGridTemplate.value.length + 1}`;
});

const pinnedEndGridColumn = computed(() => {
  if (!pinnedEndGridTemplate.value.length) {
    return 'auto';
  }

  const start = pinnedStartGridTemplate.value.length + mainGridTemplate.value.length + 1;
  return `${start} / ${start + pinnedEndGridTemplate.value.length + 1}`;
});

function renderHeadCells(columns: Column[]): VNode[] {
  return columns.map((column) => {
    const slot = slots[`head[${column.key}]`];

    return h(
      ObDataTableHeadCell,
      {
        key: column.key,
        align: column.align,
        columnKey: column.key,
        description: column.description,
        sortable: column.sortable,
        sorting: sorting.value,
        onClick: () => {
          if (column.sortable) {
            setSort(column);
          }
        },
      },
      () => (slot ? slot({ column }) : column.heading),
    );
  });
}

function renderHead(): VNode {
  return h('div', { class: style.head }, [
    props.loading ? h('div', { class: style.loader }, [h(ObLinearProgress, { size: 2 })]) : null,
    h(
      ObDataTableRow,
      {},
      {
        pinnedStart: () => [
          h('div'),
          props.rowsSelection &&
            h(
              ObDataTableHeadCell,
              {
                columnKey: SELECTION_COLUMN_KEY,
              },
              () => [
                h(ObCheckbox, {
                  indeterminate: selectedRows.value.length > 0 && !allRowsSelected.value,
                  modelValue: allRowsSelected.value,
                  'onUpdate:modelValue': (checked: boolean) => {
                    if (checked) {
                      selectedRows.value = rows.value as UnwrapNestedRefs<T[]>; // https://stackoverflow.com/questions/69813587/vue-unwraprefsimplet-generics-type-cant-assignable-to-t-at-reactive
                      return;
                    }

                    selectedRows.value = [];
                  },
                }),
              ],
            ),
          ...renderHeadCells(pinnedStartColumns.value),
        ],
        default: () => [
          renderHeadCells(notPinnedColumns.value),
          h('div', { class: [style.cell, style.headCell, style.cellDumb] }),
        ],
        pinnedEnd: pinnedEndColumns.value.length
          ? () => renderHeadCells(pinnedEndColumns.value)
          : null,
      },
    ),
  ]);
}

function renderBodyCells(row: Row, columns: Column[], rowIndex: number): VNode[] {
  return columns.map((column) => {
    const rawValue = getValue(row, column, rowIndex);

    const value = toString(
      typeof column.valueFormatter === 'function'
        ? column.valueFormatter(rawValue as any, row)
        : rawValue,
    );

    const slot = slots[`cell[${column.key}]`];

    return h(
      ObDataTableBodyCell,
      {
        key: column.key,
        align: column.align,
        monospace: column.monospace,
        onClick: (event: MouseEvent) => {
          emit('cellClick', { event, row, column });
        },
      },
      () => (slot ? slot({ value, rawValue, row, column, rowIndex }) : value),
    );
  });
}

function renderBody(): VNode {
  return h(
    'div',
    { class: style.body },
    rows.value.map((row, index) => {
      const selected = isRowSelected(row);
      return h(
        ObDataTableRow,
        {
          key: rowsKeys.value[index] || index,
          selected,
          // TODO: check if these events are needed
          onClick: (event: MouseEvent) => {
            emit('rowClick', { event, row });
          },
          onMouseover: (event: MouseEvent) => {
            emit('rowHover', { event, row });
          },
        },
        {
          pinnedStart: () => [
            h('div', { class: selected ? style.selectedRowMark : null }),
            props.rowsSelection &&
              h(ObDataTableBodyCell, {}, () =>
                h(ObCheckbox, {
                  value: row,
                  trackBy: props.rowKey,
                  multiselect: true,
                  modelValue: selectedRows.value,
                  'onUpdate:modelValue': (value: Row[]) =>
                    (selectedRows.value = value as UnwrapNestedRefs<T[]>), // https://stackoverflow.com/questions/69813587/vue-unwraprefsimplet-generics-type-cant-assignable-to-t-at-reactive
                }),
              ),
            ...renderBodyCells(row, pinnedStartColumns.value, index),
          ],
          default: () => [
            renderBodyCells(row, notPinnedColumns.value, index),
            h('div', { class: [style.cell, style.bodyCell, style.cellDumb] }),
          ],
          pinnedEnd: pinnedEndColumns.value.length
            ? () => renderBodyCells(row, pinnedEndColumns.value, index)
            : null,
        },
      );
    }),
  );
}

defineRender(() => {
  const beforeHead = slots.beforeHead?.();

  return h(
    'div',
    {
      class: [
        style.root,
        {
          [style.stickyHead]: props.stickyHead,
          [style.compact]: props.compact,
        },
      ],
      style: {
        '--pinned-start-grid-column': pinnedStartGridColumn.value,
        '--pinned-start-grid-template-columns': pinnedStartGridTemplate.value.join(' '),
        '--grid-template-columns': gridTemplate.value.join(' '),
        '--pinned-end-grid-template-columns': pinnedEndGridTemplate.value.join(' '),
        '--pinned-end-grid-column': pinnedEndGridColumn.value,
      },
    },
    [
      !isVNodeEmpty(beforeHead)
        ? h(
            'div',
            {
              class: style.beforeHead,
            },
            beforeHead,
          )
        : null,
      h(
        ObScrollableContainer,
        {
          ref: scrollerEl,
          compact: true,
          overscrollBehavior: ['contain', 'auto'],
        },
        () => h('div', { class: style.grid }, [renderHead(), renderBody()]),
      ),
      props.paginationEnabled
        ? h(
            'div',
            { class: style.footer },
            h(ObDataTablePagination, {
              total: totalRows.value,
              pageSizeOptions: props.pageSizeOptions,
              page: pagination.value.page,
              'onUpdate:page': (value: number) => {
                if (value === pagination.value.page) {
                  return;
                }

                pagination.value = {
                  page: value,
                  pageSize: pagination.value.pageSize,
                };

                emitStateChange();
              },
              pageSize: pagination.value.pageSize,
              'onUpdate:pageSize': (value: number) => {
                if (value === pagination.value.pageSize) {
                  return;
                }

                pagination.value = {
                  page: 1,
                  pageSize: value,
                };

                emitStateChange();
              },
            }),
          )
        : null,
    ],
  );
});
</script>
