import { reactive } from 'vue';
import { createUniqueId } from '../../utils';

class ReactiveClass {
  constructor() {
    return reactive(this);
  }
}

interface IFileUploaderUploading {
  id: string;
  state: 'uploading' | 'completed' | 'error' | 'aborted';
  progress: number;
  file: File;
  abortSignal: AbortSignal;
  result: any;
  errorMessage: string | null;
  abortController: AbortController;
  uploadHandler: (uploading: IFileUploaderUploading) => void;
  onBeforeRemove?: (uploading: IFileUploaderUploading) => Promise<boolean> | boolean;
  onBeforeAbort?: (uploading: IFileUploaderUploading) => Promise<boolean> | boolean;
  onError?: (uploading: IFileUploaderUploading) => void;
  onCompleted?: (uploading: IFileUploaderUploading) => void;
  onRemoved?: (uploading: IFileUploaderUploading) => void;
  onAborted?: (uploading: IFileUploaderUploading) => void;
  complete: (result?: any) => void;
  setError: (error?: string) => void;
  setProgress: (progress: number) => void;
  start: () => void;
  retry: () => void;
  abort: () => Promise<void>;
  remove: () => Promise<void>;
}

type FileUploaderUploadingParams = {
  file: File;
  uploadHandler: (uploading: IFileUploaderUploading) => void;
  onBeforeRemove?: (uploading: IFileUploaderUploading) => Promise<boolean> | boolean;
  onBeforeAbort?: (uploading: IFileUploaderUploading) => Promise<boolean> | boolean;
  onError?: (uploading: IFileUploaderUploading) => void;
  onCompleted?: (uploading: IFileUploaderUploading) => void;
  onRemoved?: (uploading: IFileUploaderUploading) => void;
  onAborted?: (uploading: IFileUploaderUploading) => void;
};

export class FileUploaderUploading extends ReactiveClass implements IFileUploaderUploading {
  id: string;
  state: 'uploading' | 'completed' | 'error' | 'aborted';
  progress: number;
  file: File;
  abortSignal: AbortSignal;
  result: any;
  errorMessage: string | null;
  uploadHandler: (uploading: IFileUploaderUploading) => void;
  onBeforeRemove?: (uploading: IFileUploaderUploading) => Promise<boolean> | boolean;
  onBeforeAbort?: (uploading: IFileUploaderUploading) => Promise<boolean> | boolean;
  onError?: (uploading: IFileUploaderUploading) => void;
  onCompleted?: (uploading: IFileUploaderUploading) => void;
  onRemoved?: (uploading: IFileUploaderUploading) => void;
  onAborted?: (uploading: IFileUploaderUploading) => void;
  abortController: AbortController;

  constructor(params: FileUploaderUploadingParams) {
    super();

    this.uploadHandler = params.uploadHandler;
    this.onBeforeRemove = params.onBeforeRemove;
    this.onBeforeAbort = params.onBeforeAbort;
    this.onError = params.onError;
    this.onCompleted = params.onCompleted;
    this.onRemoved = params.onRemoved;
    this.onAborted = params.onAborted;

    this.abortController = new AbortController();

    this.id = createUniqueId();
    this.state = 'uploading'; // TODO: different
    this.progress = 0;
    this.file = params.file;
    this.result = null;
    this.errorMessage = null;
    this.abortSignal = this.abortController.signal;
  }

  // TODO: prevent call if already started
  start() {
    this.uploadHandler(this);
  }

  async abort() {
    if (this.onBeforeAbort) {
      const result = await this.onBeforeAbort(this);
      if (result === false) {
        return;
      }
    }

    this.state = 'aborted';
    this.abortController.abort();

    this.onAborted?.(this);
  }

  setError(errorMessage: string | undefined) {
    this.state = 'error';
    this.errorMessage = errorMessage ?? '';

    this.onError?.(this);
  }

  complete(result?: any) {
    this.state = 'completed';
    this.result = result;

    this.onCompleted?.(this);
  }

  setProgress(progress: number) {
    this.progress = Math.min(1, Math.max(0, progress));
  }

  async remove() {
    if (this.onBeforeRemove) {
      const result = await this.onBeforeRemove(this);
      if (result === false) {
        return;
      }
    }

    this.onRemoved?.(this);
  }

  retry() {
    this.state = 'uploading';
    this.progress = 0;
    this.errorMessage = null;
    this.result = null;

    this.start();
  }
}
