<script lang="ts" setup>
import { ref, computed, provide } from 'vue';
import { IconX } from '@tabler/icons-vue';
import { useZIndex, useConfig } from '../../composables';
import { ObUnmountableTeleportWithTransition } from '../internal';
import { BATCH_ACTIONS_MENU_CONTEXT } from './shared';

interface Props {
  itemsCount: number;
  active?: boolean;
  withCloseButton?: boolean;
  labelSelectedItems?: (count: number) => string;
}

const { itemsCount = 0, withCloseButton = true, labelSelectedItems } = defineProps<Props>();

const emit = defineEmits<{
  'update:active': [active: boolean];
  activated: [];
  deactivated: [];
}>();

const active = defineModel<boolean>('active', { default: false });

function activate() {
  active.value = true;
  emit('activated');
}

function deactivate() {
  active.value = false;
  emit('deactivated');
}

function toggle() {
  if (active.value) {
    deactivate();
    return;
  }

  activate();
}

const { zIndex } = useZIndex({ active });

const registeredItems = ref<string[]>([]);

const verticalItemsLayout = computed<boolean>(() => registeredItems.value.length > 1);

function register(itemId: string) {
  if (!registeredItems.value.find((id) => id == itemId)) {
    registeredItems.value.push(itemId);
  }
}

function unregister(itemId: string) {
  registeredItems.value = registeredItems.value.filter((id) => id !== itemId);
}

provide(BATCH_ACTIONS_MENU_CONTEXT, {
  register,
  unregister,
  verticalItemsLayout,
});

const config = useConfig();

const label = computed(() => {
  const customLabel =
    labelSelectedItems ?? config?.value?.i18n?.BatchActionsMenu?.labelSelectedItems;

  if (customLabel) {
    return customLabel(itemsCount);
  }

  return `item${itemsCount > 1 ? 's' : ''} selected`;
});

const sharedSlotScope = { active, activate, deactivate, toggle };
</script>

<template>
  <slot name="activator" v-bind="sharedSlotScope" />
  <ObUnmountableTeleportWithTransition
    :active
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.leaveActive"
    :leave-to-class="$style.leaveTo"
  >
    <div
      v-if="active"
      :class="[$style.root, { [$style.vertical]: verticalItemsLayout }]"
      role="dialog"
      :style="{ zIndex }"
    >
      <div :class="$style.inner">
        <div :class="$style.state">
          <div :class="$style.total">{{ itemsCount }}</div>
          <div :class="$style.text">{{ label }}</div>
        </div>
        <div :class="$style.items">
          <slot v-bind="sharedSlotScope" />
        </div>
        <div v-if="withCloseButton" :class="$style.close">
          <button
            :class="$style.closeButton"
            type="button"
            aria-label="Close"
            @click="deactivate()"
          >
            <IconX aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </ObUnmountableTeleportWithTransition>
</template>

<style lang="scss" module>
@use '../../styles/colors';
@use '../../styles/shared';
@use '../../styles/typography';
@use '../../styles/iconography';

.root {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 40px;
  left: 0;
  right: 0;
  pointer-events: none;
}

.inner {
  pointer-events: auto;
  background: #fff;
  color: colors.$primary;
  display: flex;
  box-sizing: content-box;
  border: 1px solid #d7d9e2; // TODO: use design token
  box-shadow: 0px 0px 18px rgba(2, 17, 72, 0.2); // TODO: use design token
  border-radius: 12px;
  overflow: hidden;
  font-family: typography.$font-family-primary;
  font-size: 13px;
  line-height: 20px;
  height: 100px;
  max-width: 80vw;
}

.state {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #35416d; // TODO: use design token
  min-width: 120px;
  padding: 0 14px;
  flex: none;
}

.total {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 8px;
}

.text {
  font-size: 13px;
  line-height: 16px;
}

.items {
  @include shared.scrollbar();
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
  overflow-y: auto;
  padding: 0 24px;
  gap: 24px;
  border-right: 1px solid rgba(2, 17, 72, 0.04); // TODO: correct color
}

.close {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0 14px;
}

.closeButton {
  @include shared.reset-button();
  box-sizing: content-box;
  flex: none;
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  padding: 4px;
  box-sizing: border-box;

  > svg {
    width: 100%;
    height: 100%;
  }

  &:hover:enabled {
    color: #7a68e3;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px colors.$hyperlink;
  }
}

.enterActive {
  transition: all 0.1s ease-out;
}

.leaveActive {
  transition: all 0.1s ease-in;
}

.enterFrom,
.leaveTo {
  opacity: 0;
  transform: translateY(20px);
}
</style>
